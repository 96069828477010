@import "./fonts.scss";
@import "./colors.scss";


#root {
  background-color: black;
}

.google-map {
  height: 400px;
}

body {
  background-color: black;
  height: 100%;
}

html {
  background-color: black;
  height: 100%;
}

div.container {
  margin-top: 15vh;
  margin-bottom: 2rem;
}

.fade {
  animation: fadein 1s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.column {
  p {
    font-family: $body-font;
    color: white;
    font-size: 1rem;
    margin: 1rem;
    a {
      &:hover {
        color: $blue;
      }
    }
  }
}

.section, .hero {
  h1,
  h2,
  h3 {
    font-family: $heading-font;
    color: white;
    font-weight: 400;
    text-transform: uppercase;
  }

  .section-header {
    margin-top: 2rem;
  }
}

.section h1.title.is-1 {
  font-size: 1.75rem;
  letter-spacing: 0.4rem;
}

.section h2, .sub-title{
  font-size: 1.25rem;
}

.section h3, .sub-title {
  font-size: 1.15rem;
  text-transform: none;
}

.section h2.people-name {
  font-size: 1.25rem;
}

footer {
  background-color: black;
  width: 100%;
  left: 0px;
  bottom: 0px;
  padding: 0;
  align-content: flex-end;
  align-items: baseline;
  .footer-content {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}



@media (max-width: 768px) {
  div.container {
    margin-top: 5vh;
    margin-bottom: 10vh;
    padding-bottom: 0;
  }

  footer {
    position: static;
  }


}
