$heading-font: "Lulo Bold", sans-serif;
$body-font: "Helvetica", sans-serif;

.page-content {
  p {
    margin-left: 0;
    color: white;
    strong {
      font-weight: 700;
      color: white;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
  #bannerR {
    color: white;
    strong {
      font-weight: 700;
      color: white;
    }
    font-family: $body-font;
    font-family: $body-font;
    color: white;
    font-size: 1rem;
    margin: 2rem, 1rem, 1rem 0;
  }
}

.page-content-centered {
  p {
    margin-left: 0;
    color: white;
    text-align: center;
    strong {
      font-weight: 700;
      color: white;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
  #bannerR {
    color: white;
    strong {
      font-weight: 700;
      color: white;
    }
    font-family: $body-font;
    font-family: $body-font;
    color: white;
    font-size: 1rem;
    margin: 2rem, 1rem, 1rem 0;
  }
}

h3.sub-title {
  font-size: 1.5rem;
}

@font-face {
  font-family:"Lulo Clean";
  src: url('../../assets/fonts/lulo-clean-family/lulo-clean-one.otf');
}
