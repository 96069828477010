@import "./common/fonts.scss";
@import "./common/colors.scss";

.home-wrapper {
  height: 95vh;
  background-color: black;
}

.company {
  position: absolute;
  top: 3vw;
  left: 10vw;
  width: 18vw;
  height: 18vw;
}

.news {
  position: absolute;
  top: 3vw;
  left: 36vw;
  width: 16vw;
  height: 16vw;
}

.people {
  position: absolute;
  top: 20vw;
  left: 26vw;
  width: 15vw;
  height: 15vw;
}

.contact {
  position: absolute;
  top: 17vw;
  left: 64vw;
  width: 17vw;
  height: 17vw;
}

.shows {
  position: absolute;
  top: 27vw;
  left: 44vw;
  width: 15vw;
  height: 15vw;
}

.daydream {
  position: absolute;
  top: 33vw;
  left: 78vw;
  height: 11vw;
  width: 11vw;
}

.blank-one {
  display: block;
  position: absolute;
  top: 35vw;
  left: 18vw;
  width: 8vw;
  height: 8vw;
}

.blank-two {
  position: absolute;
  top: 16vw;
  left: 53vw;
  width: 8vw;
  height: 8vw;
}

.blank-three {
  position: absolute;
  top: 7vw;
  left: 62vw;
  width: 7vw;
  height: 7vw;
}

.blank-four {
  position: absolute;
  top: 35vw;
  left: 62vw;
  width: 8vw;
  height: 8vw;
}

.blank-six {
  position: absolute;
  top: 17vw;
  left: 83vw;
  height: 6vw;
  width: 6vw;
}

.blank-seven {
  position: absolute;
  top: 23vw;
  left: 15vw;
  width: 7vw;
  height: 7vw;
}

.pumpkin {
  background-color: $pumpkin;
}

.white {
  background-color: $white;
}
.green {
  background-color: $green;
}

.yellow {
  background-color: $yellow;
}

.blue {
  background-color: $blue;
}

.pink {
  background-color: $pink;
}

.circle {
  border-radius: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.red {
  background-color: $red;
}

.home-logo {
  position: absolute;
  height: 15vw;
  top: 0vw;
  left: 73vw;
}
h1.inner-circle {
  position: relative;
  color: black;
  text-transform: uppercase;
  font-family: $heading-font;
  font-size: 1.7vw;
  letter-spacing: 0.5rem;
}



@media (max-width: 700px) {
  .company {
    position: absolute;
    top: 3vh;
    left: 5vh;
    width: 24vh;
    height: 24vh;
  }

  .news {
    position: absolute;
    top: 20vh;
    left: 28vh;
    width: 18vh;
    height: 18vh;
  }

  .people {
    position: absolute;
    top: 35vh;
    left: 8vh;
    width: 20vh;
    height: 20vh;
  }

  .contact {
    position: absolute;
    top: 65vh;
    left: 33vh;
    width: 21vh;
    height: 21vh;
  }

  .shows {
    position: absolute;
    top: 40vh;
    left: 35vh;
    width: 18vh;
    height: 18vh;
  }

  .daydream {
    position: absolute;
    top: 65vh;
    left: 8vh;
    height: 16vh;
    width: 16vh;
  }

  .blank-one {
    display: block;
    position: absolute;
    top: 4vh;
    left: 28vh;
    width: 6vh;
    height: 6vh;
  }

  .blank-two {
    position: absolute;
    top: 20vh;
    left: 47vh;
    width: 8vh;
    height: 8vh;
  }

  .blank-three {
    position: absolute;
    top: 58vh;
    left: 26vh;
    width: 7vh;
    height: 7vh;
  }

  .blank-four {
    position: absolute;
    top: 53vh;
    left: 3vh;
    width: 9vh;
    height: 9vh;
  }

  .blank-six {
    position: absolute;
    top: 82vh;
    left: 27vh;
    height: 5vh;
    width: 5vh;
  }

  .blank-seven {
    position: absolute;
    top: 28vh;
    left: 6vh;
    width: 7vh;
    height: 7vh;
  }

  .circle {
    border-radius: 100%;
  }

  h1.inner-circle {
    font-size: 2.5vh;
    letter-spacing: 0.25rem;
  }

  .home-logo {
    top: -5vw;
    left: 48vw;
    height: 24vh;
  }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .home-wrapper {
      height: 85vh;
    }

    .company {
      top: 10vw;
      left: 10vw;
      width: 20vw;
      height: 20vw;
    }
  
    .news {
      top: 10vw;
      left: 36vw;
      width: 18vw;
      height: 18vw;
    }
  
    .people {
      top: 31vw;
      left: 25vw;
      width: 16vw;
      height: 16vw;
    }
  
    .contact {
      top: 24vw;
      left: 64vw;
      width: 17vw;
      height: 17vw;
    }
  
    .shows {
      top: 37vw;
      left: 44vw;
      width: 17vw;
      height: 17vw;
    }
  
    .daydream {
      top: 40vw;
      left: 78vw;
      height: 13vw;
      width: 13vw;
    }
  
    .blank-one {
      top: 46vw;
      left: 18vw;
      width: 10vw;
      height: 10vw;
    }
  
    .blank-two {
      top: 27vw;
      left: 53vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-three {
      top: 16vw;
      left: 62vw;
      width: 7vw;
      height: 7vw;
    }
  
    .blank-four {
      position: absolute;
      top: 46vw;
      left: 62vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-six {
      position: absolute;
      top: 28vw;
      left: 83vw;
      height: 6vw;
      width: 6vw;
    }
  
    .blank-seven {
      position: absolute;
      top: 34vw;
      left: 15vw;
      width: 7vw;
      height: 7vw;
    }
  
    .pumpkin {
      background-color: $pumpkin;
    }
  
    .white {
      background-color: $white;
    }
    .green {
      background-color: $green;
    }
  
    .yellow {
      background-color: $yellow;
    }
  
    .blue {
      background-color: $blue;
    }
  
    .pink {
      background-color: $pink;
    }
  
    .circle {
      border-radius: 100%;
      text-align: center;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    .red {
      background-color: $red;
    }
  
    .home-logo {
      position: absolute;
      height: 15vw;
      top: 0vw;
      left: 73vw;
    }
    h1.inner-circle {
      position: relative;
      color: black;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.7vw;
      letter-spacing: 0.2rem;
    }
}





@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .home-wrapper {
      height: 85vh;
    }

    .company {
      top: 10vw;
      left: 10vw;
      width: 20vw;
      height: 20vw;
    }
  
    .news {
      top: 10vw;
      left: 36vw;
      width: 18vw;
      height: 18vw;
    }
  
    .people {
      top: 31vw;
      left: 25vw;
      width: 16vw;
      height: 16vw;
    }
  
    .contact {
      top: 24vw;
      left: 64vw;
      width: 17vw;
      height: 17vw;
    }
  
    .shows {
      top: 37vw;
      left: 44vw;
      width: 17vw;
      height: 17vw;
    }
  
    .daydream {
      top: 40vw;
      left: 78vw;
      height: 13vw;
      width: 13vw;
    }
  
    .blank-one {
      top: 46vw;
      left: 18vw;
      width: 10vw;
      height: 10vw;
    }
  
    .blank-two {
      top: 27vw;
      left: 53vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-three {
      top: 16vw;
      left: 62vw;
      width: 7vw;
      height: 7vw;
    }
  
    .blank-four {
      position: absolute;
      top: 46vw;
      left: 62vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-six {
      position: absolute;
      top: 28vw;
      left: 83vw;
      height: 6vw;
      width: 6vw;
    }
  
    .blank-seven {
      position: absolute;
      top: 34vw;
      left: 15vw;
      width: 7vw;
      height: 7vw;
    }
  
    .pumpkin {
      background-color: $pumpkin;
    }
  
    .white {
      background-color: $white;
    }
    .green {
      background-color: $green;
    }
  
    .yellow {
      background-color: $yellow;
    }
  
    .blue {
      background-color: $blue;
    }
  
    .pink {
      background-color: $pink;
    }
  
    .circle {
      border-radius: 100%;
      text-align: center;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    .red {
      background-color: $red;
    }
  
    .home-logo {
      position: absolute;
      height: 15vw;
      top: 0vw;
      left: 73vw;
    }
    h1.inner-circle {
      position: relative;
      color: black;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.7vw;
      letter-spacing: 0.2rem;
    }
}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .company {
      position: absolute;
      top: 3vh;
      left: 5vh;
      width: 24vh;
      height: 24vh;
    }
  
    .news {
      position: absolute;
      top: 20vh;
      left: 28vh;
      width: 18vh;
      height: 18vh;
    }
  
    .people {
      position: absolute;
      top: 35vh;
      left: 8vh;
      width: 20vh;
      height: 20vh;
    }
  
    .contact {
      position: absolute;
      top: 65vh;
      left: 33vh;
      width: 21vh;
      height: 21vh;
    }
  
    .shows {
      position: absolute;
      top: 40vh;
      left: 35vh;
      width: 18vh;
      height: 18vh;
    }
  
    .daydream {
      position: absolute;
      top: 65vh;
      left: 8vh;
      height: 16vh;
      width: 16vh;
    }
  
    .blank-one {
      display: block;
      position: absolute;
      top: 4vh;
      left: 28vh;
      width: 6vh;
      height: 6vh;
    }
  
    .blank-two {
      position: absolute;
      top: 20vh;
      left: 47vh;
      width: 8vh;
      height: 8vh;
    }
  
    .blank-three {
      position: absolute;
      top: 58vh;
      left: 26vh;
      width: 7vh;
      height: 7vh;
    }
  
    .blank-four {
      position: absolute;
      top: 53vh;
      left: 3vh;
      width: 9vh;
      height: 9vh;
    }
  
    .blank-six {
      position: absolute;
      top: 82vh;
      left: 27vh;
      height: 5vh;
      width: 5vh;
    }
  
    .blank-seven {
      position: absolute;
      top: 28vh;
      left: 6vh;
      width: 7vh;
      height: 7vh;
    }
  
    .circle {
      border-radius: 100%;
    }
  
    h1.inner-circle {
      font-size: 2.5vh;
      letter-spacing: 0.25rem;
    }
  
    .home-logo {
      top: -5vw;
      left: 48vw;
      height: 24vh;
    }
}


@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .home-wrapper {
      height: 85vh;
    }

    .company {
      top: 10vw;
      left: 10vw;
      width: 20vw;
      height: 20vw;
    }
  
    .news {
      top: 10vw;
      left: 36vw;
      width: 18vw;
      height: 18vw;
    }
  
    .people {
      top: 31vw;
      left: 25vw;
      width: 16vw;
      height: 16vw;
    }
  
    .contact {
      top: 24vw;
      left: 64vw;
      width: 17vw;
      height: 17vw;
    }
  
    .shows {
      top: 37vw;
      left: 44vw;
      width: 17vw;
      height: 17vw;
    }
  
    .daydream {
      top: 40vw;
      left: 78vw;
      height: 13vw;
      width: 13vw;
    }
  
    .blank-one {
      top: 46vw;
      left: 18vw;
      width: 10vw;
      height: 10vw;
    }
  
    .blank-two {
      top: 27vw;
      left: 53vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-three {
      top: 16vw;
      left: 62vw;
      width: 7vw;
      height: 7vw;
    }
  
    .blank-four {
      position: absolute;
      top: 46vw;
      left: 62vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-six {
      position: absolute;
      top: 28vw;
      left: 83vw;
      height: 6vw;
      width: 6vw;
    }
  
    .blank-seven {
      position: absolute;
      top: 34vw;
      left: 15vw;
      width: 7vw;
      height: 7vw;
    }
  
    .pumpkin {
      background-color: $pumpkin;
    }
  
    .white {
      background-color: $white;
    }
    .green {
      background-color: $green;
    }
  
    .yellow {
      background-color: $yellow;
    }
  
    .blue {
      background-color: $blue;
    }
  
    .pink {
      background-color: $pink;
    }
  
    .circle {
      border-radius: 100%;
      text-align: center;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    .red {
      background-color: $red;
    }
  
    .home-logo {
      position: absolute;
      height: 15vw;
      top: 0vw;
      left: 73vw;
    }
    h1.inner-circle {
      position: relative;
      color: black;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.7vw;
      letter-spacing: 0.2rem;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .home-wrapper {
    height: 93vh;
    background-color: black;
  }

  .company {
    position: absolute;
    top: 3vw;
    left: 10vw;
    width: 18vw;
    height: 18vw;
  }

  .news {
    position: absolute;
    top: 3vw;
    left: 36vw;
    width: 16vw;
    height: 16vw;
  }

  .people {
    position: absolute;
    top: 20vw;
    left: 26vw;
    width: 15vw;
    height: 15vw;
  }

  .contact {
    position: absolute;
    top: 17vw;
    left: 64vw;
    width: 17vw;
    height: 17vw;
  }

  .shows {
    position: absolute;
    top: 27vw;
    left: 44vw;
    width: 15vw;
    height: 15vw;
  }

  .daydream {
    position: absolute;
    top: 33vw;
    left: 78vw;
    height: 11vw;
    width: 11vw;
  }

  .blank-one {
    display: block;
    position: absolute;
    top: 35vw;
    left: 18vw;
    width: 8vw;
    height: 8vw;
  }

  .blank-two {
    position: absolute;
    top: 16vw;
    left: 53vw;
    width: 8vw;
    height: 8vw;
  }

  .blank-three {
    position: absolute;
    top: 7vw;
    left: 62vw;
    width: 7vw;
    height: 7vw;
  }

  .blank-four {
    position: absolute;
    top: 35vw;
    left: 62vw;
    width: 8vw;
    height: 8vw;
  }

  .blank-six {
    position: absolute;
    top: 17vw;
    left: 83vw;
    height: 6vw;
    width: 6vw;
  }

  .blank-seven {
    position: absolute;
    top: 23vw;
    left: 15vw;
    width: 7vw;
    height: 7vw;
  }

  .pumpkin {
    background-color: $pumpkin;
  }

  .white {
    background-color: $white;
  }
  .green {
    background-color: $green;
  }

  .yellow {
    background-color: $yellow;
  }

  .blue {
    background-color: $blue;
  }

  .pink {
    background-color: $pink;
  }

  .circle {
    border-radius: 100%;
    text-align: center;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .red {
    background-color: $red;
  }

  .home-logo {
    position: absolute;
    height: 15vw;
    top: 0vw;
    left: 73vw;
  }
  h1.inner-circle {
    position: relative;
    color: black;
    text-transform: uppercase;
    font-family: $heading-font;
    font-size: 1.7vw;
    letter-spacing: 0.2rem;
  }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .company {
      position: absolute;
      top: 3vh;
      left: 5vh;
      width: 24vh;
      height: 24vh;
    }
  
    .news {
      position: absolute;
      top: 20vh;
      left: 28vh;
      width: 18vh;
      height: 18vh;
    }
  
    .people {
      position: absolute;
      top: 35vh;
      left: 8vh;
      width: 20vh;
      height: 20vh;
    }
  
    .contact {
      position: absolute;
      top: 65vh;
      left: 33vh;
      width: 21vh;
      height: 21vh;
    }
  
    .shows {
      position: absolute;
      top: 40vh;
      left: 35vh;
      width: 18vh;
      height: 18vh;
    }
  
    .daydream {
      position: absolute;
      top: 65vh;
      left: 8vh;
      height: 16vh;
      width: 16vh;
    }
  
    .blank-one {
      display: block;
      position: absolute;
      top: 4vh;
      left: 28vh;
      width: 6vh;
      height: 6vh;
    }
  
    .blank-two {
      position: absolute;
      top: 20vh;
      left: 47vh;
      width: 8vh;
      height: 8vh;
    }
  
    .blank-three {
      position: absolute;
      top: 58vh;
      left: 26vh;
      width: 7vh;
      height: 7vh;
    }
  
    .blank-four {
      position: absolute;
      top: 53vh;
      left: 3vh;
      width: 9vh;
      height: 9vh;
    }
  
    .blank-six {
      position: absolute;
      top: 82vh;
      left: 27vh;
      height: 5vh;
      width: 5vh;
    }
  
    .blank-seven {
      position: absolute;
      top: 28vh;
      left: 6vh;
      width: 7vh;
      height: 7vh;
    }
  
    .circle {
      border-radius: 100%;
    }
  
    h1.inner-circle {
      font-size: 2.5vh;
      letter-spacing: 0.25rem;
    }
  
    .home-logo {
      top: -5vw;
      left: 48vw;
      height: 24vh;
    }
}

@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .company {
      position: absolute;
      top: 3vh;
      left: 5vh;
      width: 24vh;
      height: 24vh;
    }
  
    .news {
      position: absolute;
      top: 20vh;
      left: 28vh;
      width: 18vh;
      height: 18vh;
    }
  
    .people {
      position: absolute;
      top: 35vh;
      left: 8vh;
      width: 20vh;
      height: 20vh;
    }
  
    .contact {
      position: absolute;
      top: 65vh;
      left: 33vh;
      width: 21vh;
      height: 21vh;
    }
  
    .shows {
      position: absolute;
      top: 40vh;
      left: 35vh;
      width: 18vh;
      height: 18vh;
    }
  
    .daydream {
      position: absolute;
      top: 65vh;
      left: 8vh;
      height: 16vh;
      width: 16vh;
    }
  
    .blank-one {
      display: block;
      position: absolute;
      top: 4vh;
      left: 28vh;
      width: 6vh;
      height: 6vh;
    }
  
    .blank-two {
      position: absolute;
      top: 20vh;
      left: 47vh;
      width: 8vh;
      height: 8vh;
    }
  
    .blank-three {
      position: absolute;
      top: 58vh;
      left: 26vh;
      width: 7vh;
      height: 7vh;
    }
  
    .blank-four {
      position: absolute;
      top: 53vh;
      left: 3vh;
      width: 9vh;
      height: 9vh;
    }
  
    .blank-six {
      position: absolute;
      top: 82vh;
      left: 27vh;
      height: 5vh;
      width: 5vh;
    }
  
    .blank-seven {
      position: absolute;
      top: 28vh;
      left: 6vh;
      width: 7vh;
      height: 7vh;
    }
  
    .circle {
      border-radius: 100%;
    }
  
    h1.inner-circle {
      font-size: 2.5vh;
      letter-spacing: 0.25rem;
    }
  
    .home-logo {
      top: -5vw;
      left: 48vw;
      height: 24vh;
    }

}

@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .home-wrapper {
      height: 85vh;
    }

    .company {
      top: 10vw;
      left: 10vw;
      width: 20vw;
      height: 20vw;
    }
  
    .news {
      top: 10vw;
      left: 36vw;
      width: 18vw;
      height: 18vw;
    }
  
    .people {
      top: 31vw;
      left: 25vw;
      width: 16vw;
      height: 16vw;
    }
  
    .contact {
      top: 24vw;
      left: 64vw;
      width: 17vw;
      height: 17vw;
    }
  
    .shows {
      top: 37vw;
      left: 44vw;
      width: 17vw;
      height: 17vw;
    }
  
    .daydream {
      top: 40vw;
      left: 78vw;
      height: 13vw;
      width: 13vw;
    }
  
    .blank-one {
      top: 46vw;
      left: 18vw;
      width: 10vw;
      height: 10vw;
    }
  
    .blank-two {
      top: 27vw;
      left: 53vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-three {
      top: 16vw;
      left: 62vw;
      width: 7vw;
      height: 7vw;
    }
  
    .blank-four {
      position: absolute;
      top: 46vw;
      left: 62vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-six {
      position: absolute;
      top: 28vw;
      left: 83vw;
      height: 6vw;
      width: 6vw;
    }
  
    .blank-seven {
      position: absolute;
      top: 34vw;
      left: 15vw;
      width: 7vw;
      height: 7vw;
    }
  
    .pumpkin {
      background-color: $pumpkin;
    }
  
    .white {
      background-color: $white;
    }
    .green {
      background-color: $green;
    }
  
    .yellow {
      background-color: $yellow;
    }
  
    .blue {
      background-color: $blue;
    }
  
    .pink {
      background-color: $pink;
    }
  
    .circle {
      border-radius: 100%;
      text-align: center;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    .red {
      background-color: $red;
    }
  
    .home-logo {
      position: absolute;
      height: 15vw;
      top: 0vw;
      left: 73vw;
    }
    h1.inner-circle {
      position: relative;
      color: black;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.7vw;
      letter-spacing: 0.2rem;
    }
}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .company {
      position: absolute;
      top: 3vh;
      left: 5vh;
      width: 24vh;
      height: 24vh;
    }
  
    .news {
      position: absolute;
      top: 20vh;
      left: 28vh;
      width: 18vh;
      height: 18vh;
    }
  
    .people {
      position: absolute;
      top: 35vh;
      left: 8vh;
      width: 20vh;
      height: 20vh;
    }
  
    .contact {
      position: absolute;
      top: 65vh;
      left: 33vh;
      width: 21vh;
      height: 21vh;
    }
  
    .shows {
      position: absolute;
      top: 40vh;
      left: 35vh;
      width: 18vh;
      height: 18vh;
    }
  
    .daydream {
      position: absolute;
      top: 65vh;
      left: 8vh;
      height: 16vh;
      width: 16vh;
    }
  
    .blank-one {
      display: block;
      position: absolute;
      top: 4vh;
      left: 28vh;
      width: 6vh;
      height: 6vh;
    }
  
    .blank-two {
      position: absolute;
      top: 20vh;
      left: 47vh;
      width: 8vh;
      height: 8vh;
    }
  
    .blank-three {
      position: absolute;
      top: 58vh;
      left: 26vh;
      width: 7vh;
      height: 7vh;
    }
  
    .blank-four {
      position: absolute;
      top: 53vh;
      left: 3vh;
      width: 9vh;
      height: 9vh;
    }
  
    .blank-six {
      position: absolute;
      top: 82vh;
      left: 27vh;
      height: 5vh;
      width: 5vh;
    }
  
    .blank-seven {
      position: absolute;
      top: 28vh;
      left: 6vh;
      width: 7vh;
      height: 7vh;
    }
  
    .circle {
      border-radius: 100%;
    }
  
    h1.inner-circle {
      font-size: 2.5vh;
      letter-spacing: 0.25rem;
    }
  
    .home-logo {
      top: -5vw;
      left: 48vw;
      height: 24vh;
    }
}

@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .home-wrapper {
      height: 85vh;
    }

    .company {
      top: 10vw;
      left: 10vw;
      width: 20vw;
      height: 20vw;
    }
  
    .news {
      top: 10vw;
      left: 36vw;
      width: 18vw;
      height: 18vw;
    }
  
    .people {
      top: 31vw;
      left: 25vw;
      width: 16vw;
      height: 16vw;
    }
  
    .contact {
      top: 24vw;
      left: 64vw;
      width: 17vw;
      height: 17vw;
    }
  
    .shows {
      top: 37vw;
      left: 44vw;
      width: 17vw;
      height: 17vw;
    }
  
    .daydream {
      top: 40vw;
      left: 78vw;
      height: 13vw;
      width: 13vw;
    }
  
    .blank-one {
      top: 46vw;
      left: 18vw;
      width: 10vw;
      height: 10vw;
    }
  
    .blank-two {
      top: 27vw;
      left: 53vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-three {
      top: 16vw;
      left: 62vw;
      width: 7vw;
      height: 7vw;
    }
  
    .blank-four {
      position: absolute;
      top: 46vw;
      left: 62vw;
      width: 8vw;
      height: 8vw;
    }
  
    .blank-six {
      position: absolute;
      top: 28vw;
      left: 83vw;
      height: 6vw;
      width: 6vw;
    }
  
    .blank-seven {
      position: absolute;
      top: 34vw;
      left: 15vw;
      width: 7vw;
      height: 7vw;
    }
  
    .pumpkin {
      background-color: $pumpkin;
    }
  
    .white {
      background-color: $white;
    }
    .green {
      background-color: $green;
    }
  
    .yellow {
      background-color: $yellow;
    }
  
    .blue {
      background-color: $blue;
    }
  
    .pink {
      background-color: $pink;
    }
  
    .circle {
      border-radius: 100%;
      text-align: center;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    .red {
      background-color: $red;
    }
  
    .home-logo {
      position: absolute;
      height: 15vw;
      top: 0vw;
      left: 73vw;
    }
    h1.inner-circle {
      position: relative;
      color: black;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.7vw;
      letter-spacing: 0.2rem;
    }
}



