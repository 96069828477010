@import './common/fonts.scss';

button.gallery-button {
  font-family: $heading-font;
  border: none;
  background: none;
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  border-bottom: 1px solid white;
  cursor: pointer;
}

iframe[title="YouTube video player"] {
  height: 30vw;
}

.show-thumbnail {
  &:hover {
    opacity: .5;
  }
  cursor: pointer;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  border-right:10px solid white;
  border-left: transparent;
  background-color: black; 
  opacity: 0.5;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid white;
  border-right: transparent;
  background-color: black;
  opacity: 0.5;
}

.carousel-container {

  margin: 0;
}

@media (max-width: 769px)  {
  iframe[title="YouTube video player"] {
    height: 35vh;
  }

 .mobile-arrows {
   display: flex;
   justify-content: space-between;
  .arrow-left {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid white;
    border-left: transparent;
    background-color: black; 
    opacity: 0.5;
  }
  
  .arrow-right {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid white;
    border-right: transparent;
    background-color: black;
    opacity: 0.5;
  }
 } 
}