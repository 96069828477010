@import "./common/fonts.scss";
@import "./common/colors.scss";

nav.navbar {
  background-color: black;
  height: 18vh;
  margin: 0 auto;
  border-bottom: 1px solid white;
}

.navbar-end {
  .navbar-item {
    font-family: $heading-font;
    text-transform: uppercase;
    color: white;
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    margin-top: 10vh;
    letter-spacing: 0.4rem;
  }
  .news-nav {
    &:hover {
      background-color: $blue;
      margin-top: 10vh;
      color: black;
    }
  }
  .news-active {
    background-color: $blue;
    margin-top: 10vh;
    color: black;
  }
  .company-nav {
    &:hover {
      background-color: $red;
      margin-top: 10vh;
      color: black;
    }
  }
  .company-active {
    background-color: $red;
    margin-top: 10vh;
    color: black;
  }
  .shows-nav {
    &:hover {
      background-color: $pink;
      margin-top: 10vh;
      color: black;
    }
  }
  .shows-active {
    background-color: $pink;
    margin-top: 10vh;
    color: black;
  }
  .people-nav {
    &:hover {
      background-color: $white;
      margin-top: 10vh;
      color: black;
    }
  }
  .people-active {
    background-color: $white;
    margin-top: 10vh;
    color: black;
  }
  .daydream-nav {
    &:hover {
      background-color: $yellow;
      margin-top: 10vh;
      color: black;
    }
  }
  .daydream-active {
    background-color: $yellow;
    margin-top: 10vh;
    color: black;
  }
  .contact-nav {
    &:hover {
      background-color: $green;
      margin-top: 10vh;
      color: black;
    }
  }
  .contact-active {
    background-color: $green;
    margin-top: 10vh;
    color: black;
  }
}

@media (max-width: 1088px) {
  a span {
    color: white;
  }

  nav.navbar {
    height: 15vh;
  }
  .navbar-menu.is-active {
    background-color: black;
  }
  .navbar-burger {
    color: white;
  }
  .navbar-end {
    .navbar-item {
      margin-top: 0;
    }
    .news-nav {
      &:hover {
        margin-top: 0;
      }
    }
    .news-active {
      margin-top: 0;
    }
    .company-nav {
      &:hover {
        margin-top: 0;
      }
    }
    .company-active {
      margin-top: 0;
    }
    .shows-nav {
      &:hover {
        margin-top: 0;
      }
    }
    .shows-active {
      margin-top: 0;
    }
    .people-nav {
      &:hover {
        margin-top: 0;
      }
    }
    .people-active {
      margin-top: 0;
    }
    .daydream-nav {
      &:hover {
        margin-top: 0;
      }
    }
    .daydream-active {
      margin-top: 0;
    }
    .contact-nav {
      &:hover {
        margin-top: 0;
      }
    }
    .contact-active {
      margin-top: 0;
    }
  }
}

@media (max-width: 768px) {
  nav.navbar {
    height: 1vh;
  }
}
