$pumpkin:  #FF7F11;

$white: white;

$green: #4c934c;

$yellow: #ffb30f;

$blue:  #009fb7;

$pink: #db6c79;

$red: #f32910;
